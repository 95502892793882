<template>
    <a-spin :spinning="spinning">
        <page-header :title="l('ManageBlog')"></page-header>
        <a-card :bordered="false">
            <a-row :gutter="8">
                <a-col class="gutter-row" :span="20">
                    <a-input v-model="filterText" :placeholder="l('SearchWithThreeDot')" />
                </a-col>
                <a-col class="gutter-row" :span="4">
                    <a-button type="primary" @click="getData">
                        {{ l('Search') }}
                    </a-button>
                    <a-button @click="refreshGoFirstPage"> {{ l('Reset') }}</a-button>
                </a-col>
            </a-row>
            <!-- 按钮 -->
            <a-row :gutter="8" class="btn--container">
                <a-col class="gutter-row" :span="24">
                    <a-button type="primary" v-if="isGranted('Pages.Blog.Create')" @click="createOrEdit()">
                        <a-icon type="plus" />
                        {{ l('Create') }}
                    </a-button>
                    <a-button v-if="isGranted('Pages.Blog.ExportExcel')" @click="exportToExcel()">
                        <a-icon type="file-excel" />
                        <span>{{l("ExportToExcel")}}</span>
                    </a-button>
                    <a-button type="danger" v-if="isGranted('Pages.Blog.BatchDelete')" @click="batchDelete()">
                        <a-icon type="delete" />
                        {{ l('BatchDelete') }}
                    </a-button>
                </a-col>
            </a-row>
            
            <list-table
                :table-data="tableData"
                :total-items="totalItems"
                :columns="columns"
                :showTotal="showTotalFun"
                :actions-type="actionsType"
                :table-key="tableKey"
                @emitCreate="createItem"
                @emitEdit="editItem"
                @emitDelete="deleteItem"
                @emitRefreshData="getData"
                @emitSortData="updateSortData"
                @emitSelectChange="updateSelectChange"
                @emitOnPageChange="updatePageChange"
                @emitShowSizeChange="updateShowSizeChange"
            >   
            </list-table>

        </a-card>
    </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { BlogServiceProxy } from "@/shared/service-proxies";
import { CommonServiceProxy } from "@/shared/common-service";
import CreateOrEditBlogsComponent from "../blogs/create-or-edit-blogs";
import NewPostsComponent from "../posts/new-posts";
import { fileDownloadService } from "@/shared/utils";
import moment from "moment";
import { ListTable } from '@/components'

export default {
    mixins: [AppComponentBase],
    name: "bannerads",
    components: {ListTable},
    data() {
        return {
            spinning: false,
            // 搜索
            filterText: "",
            // 选择多少项
            selectedRowKeys: [],
            tableData: [],
            // 表格
            columns: [],
            // 总数
            totalItems: 0,
            // 当前页码
            pageNumber: 1,
            // 共多少页
            totalPages: 1,
            // 条数显示范围
            pagerange: [1, 1],
            // 显示条数
            pageSizeOptions: ["10", "20", "30", "40"],
            request: { sorting: "", maxResultCount: 10, skipCount: 0 }, //sorting {排序项 ASC/DESC}
            commonService: null,
            blogServiceProxy: null,
            actionsType: [],
            tableKey: 'BlogListDto',
        };
    },
    created() {
        this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api)
        this.blogServiceProxy = new BlogServiceProxy(this.$apiUrl, this.$api);
        // this._getFieldRoleByRoleId()
        this.initActionsType()
    },
    methods: {
        /**
         * 获取数据
         */
        getData() {
            this.spinning = true;
            this.blogServiceProxy
                .getPaged(
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(() => {
                    this.spinning = false;
                })
                .then(res => {
                    // console.log(res.items);
                    this.tableData = res.items;
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount
                    ];
                });
        },
        /**
         * 初始化需要显示的func btn
         */
        initActionsType() {
            let obj = {
                type: "BlogListDto",
                isShow: this.isGranted('Pages.Blog.Edit'),
                fns: {
                    check: { granted: false, name: this.l("查看") },
                    edit: {granted: this.isGranted('Pages.Blog.Edit'), name: this.l("Edit")},
                    create: {granted: this.isGranted('Pages.Blog.NewPost'), name: this.l("NewPost")},
                    delete: {granted: this.isGranted('Pages.Blog.Delete'), name: this.l("Delete")},
                }
            };
            this.actionsType = obj
        },
        /**
         * 排序更新data
         */
        updateSortData(newV) {
            let {columnKey, order} = newV
            this.request.sorting = order ? `${columnKey} ${order}` : ''
            this.getData();
        },
        /**
         * 分页事件
         */
        showTotalFun() {
            return this.l(
                "GridFooterDisplayText",
                this.pageNumber,
                this.totalPages,
                this.totalItems,
                this.pagerange[0],
                this.pagerange[1]
            );
        },
        /**
         * table选择事件
         */
        updateSelectChange(newV) {
            let {selectedRowKeys, selectedRows} = newV
            this.selectedRowKeys = selectedRowKeys
        },
        /**
         * 分页
         */
        updatePageChange(newV) {
            let {page, pageSize} = newV
            this.pageNumber = page;
            this.request.skipCount = (page - 1) * this.request.maxResultCount;
            this.getData();
        },
        updateShowSizeChange(newV) {
            let {current, size} = newV
            this.pageNumber = 1;
            this.request.maxResultCount = size;
            this.getData()
        },
        /**
         * 重置
         */
        refreshGoFirstPage() {
            this.filterText = "";
            this.request.skipCount = 0;
            this.getData();
        },
        /**
         * 清空
         */
        restCheckStatus() {
            this.selectedRowKeys = [];
        },
        /**
         * 单个删除
         */
        deleteItem(newV) {
            let {item} = newV
            this.spinning = true;
            this.blogServiceProxy
                .delete(item.id)
                .finally(() => {
                    this.spinning = false;
                })
                .then(() => {
                    this.refreshGoFirstPage();
                    this.$notification["success"]({
                        message: this.l("SuccessfullyDeleted")
                    });
                });
        },
        /**
         * emit新建修改
         */
        editItem(newV) {
            let {id} = newV.item
            this.createOrEdit(id)
        },
        /*
         * emit新建文章
         */
        createItem(newV) {
            let {id} =  newV.item
            this.newPost(id)
        },
        /**
         * 新建修改
         */
        createOrEdit(id) {
            ModalHelper.create(
                CreateOrEditBlogsComponent,
                { id: id },
                {
                    width: "400px"
                }
            ).subscribe(res => {
                if (res) {
                    this.refreshGoFirstPage();
                }
            });
        },
        /*
         * 新建文章
         */
        newPost(id) {
            // this.$router.push({
            //     path: "/app/blogging/create-or-edit-posts",
            //     query: { blogId: id }
            // });
            ModalHelper.create(
                NewPostsComponent,
                { id: id },
                {
                    width: "400px"
                }
            ).subscribe(res => {
                if (res) {
                    this.refreshGoFirstPage();
                }
            });
        },
        /**
         * 批量删除
         */
        batchDelete() {
            const selectCount = this.selectedRowKeys.length;
            if (selectCount <= 0) {
                abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
                return;
            }
            this.message.confirm(
                this.l("ConfirmDeleteXItemsWarningMessage", selectCount),
                res => {
                    if (res) {
                        const ids = _.map(this.selectedRowKeys);
                        console.log(ids);
                        this.spinning = true;
                        this.blogServiceProxy
                            .batchDelete(ids)
                            .finally(() => {
                                this.spinning = false;
                            })
                            .then(() => {
                                this.selectedRowKeys = [];
                                this.refreshGoFirstPage();
                                this.$notification["success"]({
                                    message: this.l("SuccessfullyDeleted")
                                });
                            });
                    }
                }
            );
        },
        /**
         * 导出为excel
         */
        exportToExcel() {
            this.spinning = true;
            this.blogServiceProxy.getToExcelFile().then(result => {
                this.spinning = false;
                fileDownloadService.downloadTempFile(result);
            });
        }
        
    }
};
</script>

<style scoped lang="less">
.btn--container {
    margin: 20px 0;
}
.pagination {
    margin: 10px auto;
    text-align: right;
}
.table-plus,
.table-delete {
    margin-left: 10px;
}
</style>
